let is_loaded_cardFadeIn = false;
let thresholdParameter = 0;
let previousPostion = 0;

function getTargetObjects() {
  let targetObjects;
  if(document.querySelectorAll('.jsCardFadeIn').length > 0) {
    targetObjects = document.querySelectorAll('.jsCardFadeIn');
  } else if(document.querySelectorAll('.jsCardFadeInNews').length > 0) {
    targetObjects = document.querySelectorAll('.jsCardFadeInNews');
  }
  return targetObjects;
}

$(window).on('load', function(){
  is_loaded_cardFadeIn = true;
  cardFadeIn();
});

$(window).on('scroll', function(){
  if(!is_loaded_cardFadeIn) {
    return false;
  }

  if(document.querySelectorAll('.is-stopped').length === getTargetObjects().length) {
    return false;
  }

  const currentPosition = $(this).scrollTop();

  if(Math.abs(previousPostion - currentPosition) > 0.1) {
    thresholdParameter = 0.2;
    cardFadeIn();
  }

  previousPostion = currentPosition;
});

function cardFadeIn() {
  const target = getTargetObjects();
  const targetArray = Array.prototype.slice.call(target);

  function addActive(entries) {
    let checkFirstActive = -1;

    // 表示画面内に is-active を付与
    entries.forEach(function(entry, i) {
      const target = entry.target;

      if(target.classList.contains('is-active')) {
        return false;
      }

      if (entry.isIntersecting) {
        if(checkFirstActive == -1) {
          checkFirstActive = i;
        }

        const delay = i * 300;
        setTimeout(function(){
          target.classList.add('is-active');
          setTimeout(() => {
            target.classList.add('is-stopped');
          }, 800);
        }, delay);
      }
    });

    // 表示表示時より前に is-active がないものが存在する場合
    if(checkFirstActive > 0) {
      entries.forEach(function(entry, i) {
        const target = entry.target;
        if(i < checkFirstActive) {
          target.classList.add('is-active');
          setTimeout(() => {
            target.classList.add('is-stopped');
          }, 800);
        }
      });
    }
  };


  const options = {
      root: null,
      rootMargin: '0px 0px',
      threshold: 0
  };

  const observer = new IntersectionObserver(addActive, options)
  targetObjects = document.querySelectorAll('.jsCardFadeInNews');
  targetArray.forEach(function (tgt) {
    if(document.querySelectorAll('.is-stopped').length === targetObjects.length) {
      observer.disconnect();
    }

    observer.unobserve(tgt);
    if(!tgt.classList.contains('is-stopped')) {
      observer.observe(tgt);
    }
  });

}
